import payload_plugin_vai2Bb86i2OzaECKN3vPekwHetpr5E_hPEITZmnEKPE from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_n5uHY2YTSOzipv1CdzsQgImqImJbR2CWQ1yC32D8ZEM from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.16.1_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_@_njxf7q23ylcl3qqfankdzvtk4a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ka__lwSzr0pjxwmAAyhRNM89_YaG8g8uELHjf6PhmNU from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.16.1_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_@_njxf7q23ylcl3qqfankdzvtk4a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Y4j__93q1NIfLQicnCeGveqPGBV42x__7vioG9UGP1s from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.16.1_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_@_njxf7q23ylcl3qqfankdzvtk4a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_t0I_CWn53XrmXyXZTY2pQUvVa1tBD5o0LK2e6lZuzqw from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.16.1_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_@_njxf7q23ylcl3qqfankdzvtk4a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vKSKkRDgZQN7wn1jYarGwjYVTCbVBZ8awW3WWtdiZQY from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.16.1_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_@_njxf7q23ylcl3qqfankdzvtk4a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_N8Q0P6KdG6TEHf_fk2TG1BXQKD4wUsVboeQGKY5uw3Y from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.16.1_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_@_njxf7q23ylcl3qqfankdzvtk4a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cCMjDE2UoYayQkwYX9R3bBCN2n_NWRLeYmHAFbDj93M from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.16.1_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_@_njxf7q23ylcl3qqfankdzvtk4a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_jM0VOuVxuOTN8tXriqzGgOqomXsI7eg3K0_F8G581RE from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/bgrand/Documents/Projects/live-display/player/.nuxt/components.plugin.mjs";
import prefetch_client_gpat__TmZyRq_VBK_EJ_dxSC2CHzXARV0nU5SjSHcb8 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.16.1_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_@_njxf7q23ylcl3qqfankdzvtk4a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import airbrake_Bg8jwkZ4L_H1MPnkD7wgDKvpz6kZ4j2d_BygqnwUuRI from "/home/bgrand/Documents/Projects/live-display/shared/plugins/airbrake.ts";
import firebase_WwjdEGvYq0NFUAl9PyT3YBBwJSPtKKEeqJWgsyZ2F94 from "/home/bgrand/Documents/Projects/live-display/shared/plugins/firebase.ts";
import luxon_cHA_u2aSqCAMjPSwHF9De6yF52m15unK8s3HhyeiqAo from "/home/bgrand/Documents/Projects/live-display/shared/plugins/luxon.ts";
import route_2MPPbxyMehpAvyXS6j4KeUIbQ8J_qa9qRE_Skhp4hZI from "/home/bgrand/Documents/Projects/live-display/shared/plugins/route.ts";
export default [
  payload_plugin_vai2Bb86i2OzaECKN3vPekwHetpr5E_hPEITZmnEKPE,
  revive_payload_client_n5uHY2YTSOzipv1CdzsQgImqImJbR2CWQ1yC32D8ZEM,
  unhead_ka__lwSzr0pjxwmAAyhRNM89_YaG8g8uELHjf6PhmNU,
  router_Y4j__93q1NIfLQicnCeGveqPGBV42x__7vioG9UGP1s,
  payload_client_t0I_CWn53XrmXyXZTY2pQUvVa1tBD5o0LK2e6lZuzqw,
  navigation_repaint_client_vKSKkRDgZQN7wn1jYarGwjYVTCbVBZ8awW3WWtdiZQY,
  check_outdated_build_client_N8Q0P6KdG6TEHf_fk2TG1BXQKD4wUsVboeQGKY5uw3Y,
  chunk_reload_client_cCMjDE2UoYayQkwYX9R3bBCN2n_NWRLeYmHAFbDj93M,
  plugin_vue3_jM0VOuVxuOTN8tXriqzGgOqomXsI7eg3K0_F8G581RE,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_gpat__TmZyRq_VBK_EJ_dxSC2CHzXARV0nU5SjSHcb8,
  airbrake_Bg8jwkZ4L_H1MPnkD7wgDKvpz6kZ4j2d_BygqnwUuRI,
  firebase_WwjdEGvYq0NFUAl9PyT3YBBwJSPtKKEeqJWgsyZ2F94,
  luxon_cHA_u2aSqCAMjPSwHF9De6yF52m15unK8s3HhyeiqAo,
  route_2MPPbxyMehpAvyXS6j4KeUIbQ8J_qa9qRE_Skhp4hZI
]