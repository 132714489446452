
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexrLP9GhV3Ohp_A4mW9_45uRurFuDtUiQvAxFc_45DR0Krr_EMeta } from "/home/bgrand/Documents/Projects/live-display/player/pages/index.vue?macro=true";
import { default as indexrMZXF_455qxLfXii8XpXLUdnsbGAEe2mqdYIt691EMF7QMeta } from "/home/bgrand/Documents/Projects/live-display/player/pages/playlists/index.vue?macro=true";
import { default as indexX__jgiQ5ZfXU274Gkf4aleKomSUKafF9wQCPG9dVb2sMeta } from "/home/bgrand/Documents/Projects/live-display/player/pages/playlists/[playlistId]/index.vue?macro=true";
import { default as _91pageId_93MU8zdyoyIpp4x_ZfjIFZZgRi8ZEXYRhBDgFfkjR9fI0Meta } from "/home/bgrand/Documents/Projects/live-display/player/pages/playlists/[playlistId]/[pageId].vue?macro=true";
import { default as no_45pagesU7Wx2AukHW7gdcImlxHwEU9oyxr7AhTJoE6PxLd1z2wMeta } from "/home/bgrand/Documents/Projects/live-display/player/pages/playlists/[playlistId]/no-pages.vue?macro=true";
import { default as _91playlistId_93YppyqhSCeSXK69vLr3KzUQy7TMTGNyicOMW92zGjxhMMeta } from "/home/bgrand/Documents/Projects/live-display/player/pages/playlists/[playlistId].vue?macro=true";
import { default as playlistsDsGQYgfDHQ6Y77yICj4gKRDIf3wPyIs_6xzcCmifaO8Meta } from "/home/bgrand/Documents/Projects/live-display/player/pages/playlists.vue?macro=true";
import { default as _91configId_938XZl8v6KeHoCjfY_xz1LnV41wUIRSdcDvjTuXqvporYMeta } from "/home/bgrand/Documents/Projects/live-display/player/pages/[configId].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/bgrand/Documents/Projects/live-display/player/pages/index.vue")
  },
  {
    name: playlistsDsGQYgfDHQ6Y77yICj4gKRDIf3wPyIs_6xzcCmifaO8Meta?.name,
    path: "/playlists",
    component: () => import("/home/bgrand/Documents/Projects/live-display/player/pages/playlists.vue"),
    children: [
  {
    name: "playlists",
    path: "",
    meta: indexrMZXF_455qxLfXii8XpXLUdnsbGAEe2mqdYIt691EMF7QMeta || {},
    redirect: "/",
    component: () => import("/home/bgrand/Documents/Projects/live-display/player/pages/playlists/index.vue")
  },
  {
    name: _91playlistId_93YppyqhSCeSXK69vLr3KzUQy7TMTGNyicOMW92zGjxhMMeta?.name,
    path: ":playlistId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/player/pages/playlists/[playlistId].vue"),
    children: [
  {
    name: "playlists-playlistId",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/player/pages/playlists/[playlistId]/index.vue")
  },
  {
    name: "playlists-playlistId-pageId",
    path: ":pageId()",
    meta: _91pageId_93MU8zdyoyIpp4x_ZfjIFZZgRi8ZEXYRhBDgFfkjR9fI0Meta || {},
    component: () => import("/home/bgrand/Documents/Projects/live-display/player/pages/playlists/[playlistId]/[pageId].vue")
  },
  {
    name: "playlists-playlistId-no-pages",
    path: "no-pages",
    component: () => import("/home/bgrand/Documents/Projects/live-display/player/pages/playlists/[playlistId]/no-pages.vue")
  }
]
  }
]
  },
  {
    name: "configId",
    path: "/:configId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/player/pages/[configId].vue")
  }
]